<template>
  <el-dialog :title="title" :visible.sync="show" class="choose-selectedPlan-checkCode" width="500px">
    <div class="main flex">
      <div class="img">
        <img :src="src" alt />
      </div>
      <div class="title">{{title}}</div>
      <el-button type="primary" @click="download" size="small">下载二维码</el-button>
      <a :href="src" ref="code" download="2021年陪审员选任计划.png" target="_blank"></a>
    </div>
  </el-dialog>
</template>

<script>
export default {
  // 查看二维码
  name: 'checkCode',
  props: {
    title: {
      tyle: String,
    },
  },
  components: {},
  data() {
    return {
      show: false,
      src: 'https://jxgh.jxtongke.com/uploads/20211015162238.mp41.jpg',
    };
  },
  methods: {
    download() {
      this.$refs.code.click();
    },
    toggle(show) {
      this.show = show;
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__title {
  font-weight: bold;
}
.el-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .main {
    flex-direction: column;
    .img {
      width: 250px;
      height: 250px;
      img {
        width: 250px;
        height: 250px;
      }
    }
    .title {
      margin: 10px 0;
    }
  }
}
</style>
